module.exports = function(app) {
  let { path } = app.$route;
  localStorage.setItem('path', path);
  app.$swal.fire({
    title: 'Tu sesión ha caducado',
    text: '',
    icon: 'info',
    timer: 1500,
  });
  app.$router.push({
    path: '/login',
  });
};
