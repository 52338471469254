import XLSX from 'xlsx';
import moment from 'moment';

function excelExport(id, filename) {
  const f = new Date();
  const today = f.getDate() + '/' + (f.getMonth() + 1) + '/' + f.getFullYear();
  const table = document.getElementById(id);
  const name = `Reporte-${id}-${today}.xlsx`;
  var services = XLSX.utils.table_to_sheet(table);

  for (const key in services) {
    if (Object.prototype.hasOwnProperty.call(services, key)) {
      if (Object.prototype.hasOwnProperty.call(services[key], 'z')) {
        if (services[key].z === 'm/d/yy') {
          // Fecha base de Excel (1 de enero de 1900) y le restamos 2 por como se crea el moment
          const fechaBaseExcel = moment('1900-01-01').subtract(2, 'days');

          // Agregar los días del número de serie de fecha a la fecha base de Excel
          const fecha = fechaBaseExcel.add(services[key].v, 'days');

          // Formatear la fecha como desees
          const fechaFormateada = fecha.format('DD/MM/YYYY');

          services[key].v = fechaFormateada;
          services[key].t = 's';
        }
      }
    }
  }

  var wb = XLSX.utils.book_new(); // make Workbook of Excel
  XLSX.utils.book_append_sheet(wb, services, filename);
  XLSX.writeFile(wb, name); // name of the file is 'book.xlsx'
}

function excelExportHorasLaboradasPorMes(personal) {
  // console.log('personal -> ', personal);
  const f = new Date();
  const today = f.getDate() + '/' + (f.getMonth() + 1) + '/' + f.getFullYear();
  const name = `Reporte-horas-mensual-${today}.xlsx`;

  // Definimos las variables que contendrán los días de asistencia
  let semanasTrabajadas = [];
  let asistenciasPorDia = [];
  let cantidadMaximaDeDiasAMostrar = 0;

  // Crear una nueva hoja de cálculo
  var sheet = XLSX.utils.aoa_to_sheet([]); // Inicializar como una matriz vacía

  // Definir arreglo de fusiones de celdas para luego configurar la hoja
  var merges = [];

  // Variable para saber enq ue fila debe iniciar cada persona del reporte, inica en la 3ra fila
  let filaDondeDebeComenzarLaPersona = 2;

  // Iterar sobre el array de objetos y agregar los datos a la hoja de cálculo
  personal.forEach((persona) => {
    semanasTrabajadas = [];
    asistenciasPorDia = [];
    // console.log(persona.nombre, persona);
    // se evalúan máximo 6 semanas por mes
    for (let index = 0; index < 6; index++) {
      if (persona[`semana${index}`]) {
        semanasTrabajadas.push(persona[`semana${index}`]);
      }
    }

    // se extraen las jornadas por día de cada semana
    semanasTrabajadas.map((semanaTrabajada) => {
      asistenciasPorDia.push(...semanaTrabajada.jornadasPorDias);
      return semanaTrabajada;
    });

    // console.log(asistenciasPorDia);
    let cantidadDeJornadasParaFilasAFusionar = 0;
    if (asistenciasPorDia.length >= cantidadMaximaDeDiasAMostrar) {
      cantidadMaximaDeDiasAMostrar = asistenciasPorDia.length;
    }
    asistenciasPorDia.map((asistenciaDePersona) => {
      // obtener el día de la asistencia
      let nroDiaAsistencia = moment(asistenciaDePersona.fechaAsistencia)
        .tz('America/Lima')
        .format('D');
      if (
        asistenciaDePersona.datos_laborales.switchSujetoAJornadaIntermitente
      ) {
        // si el día de esta asistencia fue jornada intermitente
        if (
          asistenciaDePersona.jornadas &&
          asistenciaDePersona.jornadas.length > 0
        ) {
          //se revisa cuántas filas se deben fusionar en base a la cantidad de jornadas intermitentes de una persona
          if (
            asistenciaDePersona.jornadas.length >
            cantidadDeJornadasParaFilasAFusionar
          ) {
            cantidadDeJornadasParaFilasAFusionar =
              asistenciaDePersona.jornadas.length;
          }
          persona[nroDiaAsistencia] = asistenciaDePersona.jornadas;
        } else {
          if (cantidadDeJornadasParaFilasAFusionar < 1) {
            cantidadDeJornadasParaFilasAFusionar = 1;
          }
          persona[nroDiaAsistencia] = [
            { hora_inicio_jornada: '', hora_fin_jornada: '' },
          ];
        }
      } else {
        if (
          (asistenciaDePersona.hora_inicio_almuerzo ||
            asistenciaDePersona.hora_fin_almuerzo) &&
          (asistenciaDePersona.hora_inicio_almuerzo !== '' ||
            asistenciaDePersona.hora_fin_almuerzo !== '')
        ) {
          cantidadDeJornadasParaFilasAFusionar = 2;
        } else {
          cantidadDeJornadasParaFilasAFusionar = 1;
        }
        persona[nroDiaAsistencia] = [
          {
            hora_inicio_jornada: asistenciaDePersona.hora_inicio_jornada,
            hora_fin_jornada: asistenciaDePersona.hora_fin_jornada,
            hora_inicio_almuerzo: asistenciaDePersona.hora_inicio_almuerzo,
            hora_fin_almuerzo: asistenciaDePersona.hora_fin_almuerzo,
          },
        ];
      }

      return asistenciaDePersona;
    });

    // Se agrega un campo para validar más adelante cuántas filas hay que agregar por persona
    persona.cantidadFilasAFusionar = cantidadDeJornadasParaFilasAFusionar;

    // Fusionar celdas por cada campo declarado en los headers
    merges.push(
      {
        s: { r: filaDondeDebeComenzarLaPersona, c: 0 }, // inicio de la fusión
        e: {
          r:
            filaDondeDebeComenzarLaPersona +
            cantidadDeJornadasParaFilasAFusionar -
            1,
          c: 0,
        }, // fin de la fusión
      },
      {
        s: { r: filaDondeDebeComenzarLaPersona, c: 1 },
        e: {
          r:
            filaDondeDebeComenzarLaPersona +
            cantidadDeJornadasParaFilasAFusionar -
            1,
          c: 1,
        },
      },
      {
        s: { r: filaDondeDebeComenzarLaPersona, c: 2 },
        e: {
          r:
            filaDondeDebeComenzarLaPersona +
            cantidadDeJornadasParaFilasAFusionar -
            1,
          c: 2,
        },
      },
      {
        s: { r: filaDondeDebeComenzarLaPersona, c: 3 },
        e: {
          r:
            filaDondeDebeComenzarLaPersona +
            cantidadDeJornadasParaFilasAFusionar -
            1,
          c: 3,
        },
      }
    );
    filaDondeDebeComenzarLaPersona =
      filaDondeDebeComenzarLaPersona + cantidadDeJornadasParaFilasAFusionar;
  });

  // ###################### CONFIGURACION DE ENCABEZADOS #####################################

  // Se definie la variable que contendrá los headers finales a mostrar
  let headersFinales = [];

  // Definir los encabezados del reporte
  const headersPrincipales = ['Documento', 'Nombre', 'Cargo', 'Sede'];

  // Definir los encabezados secundarios del reporte (se declaran aquí porque se recorren las asistencias)
  const headersSecundarios = ['', '', '', '']; // Se dejan 4 espacios vacíos que corresponden a los espacios de las 4 columnas iniciales

  // Crear fusiones de los encabezados principales
  merges.push(
    {
      s: { r: 0, c: 0 }, // inicio de la fusión
      e: { r: 1, c: 0 }, // fin de la fusión
    },
    {
      s: { r: 0, c: 1 },
      e: { r: 1, c: 1 },
    },
    {
      s: { r: 0, c: 2 },
      e: { r: 1, c: 2 },
    },
    {
      s: { r: 0, c: 3 },
      e: { r: 1, c: 3 },
    }
  );

  // Agregar fusiones de celdas a la hoja de cálculo
  sheet['!merges'] = merges;

  const headersJornadas = [];
  let ultimaColumnaExcelModificada = 4;

  for (let index = 0; index < cantidadMaximaDeDiasAMostrar; index++) {
    headersJornadas.push(...[`Día ${index + 1}`, '', '']); // se agregan 2 espacios que coresponden a celdas vacías para que los encabezados cuadren
    headersSecundarios.push(...['Tipo', 'Inicio', 'Fin']);
    merges.push({
      s: { r: 0, c: ultimaColumnaExcelModificada },
      e: { r: 0, c: ultimaColumnaExcelModificada + 2 },
    });
    ultimaColumnaExcelModificada = ultimaColumnaExcelModificada + 3;
  }

  headersFinales.push(...headersPrincipales, ...headersJornadas); // en un map agregar 2 espacios cada 1 jornada para completear las 3 celdas

  // console.log(headersFinales);

  // Agregar fusiones de celdas a la hoja de cálculo
  sheet['!merges'] = merges;

  // Agregar los encabezados al sheet
  XLSX.utils.sheet_add_aoa(sheet, [headersFinales], { origin: 0 });

  // Agregar los encabezados secundraios al sheet
  XLSX.utils.sheet_add_aoa(sheet, [headersSecundarios], { origin: -1 });
  // #############################################################################

  // Iterar sobre los datos de cada persona
  personal.forEach((persona) => {
    const rowDataPersona = [
      persona.doi,
      persona.nombre,
      persona.datos_laborales.cargo,
      persona.datos_laborales.sede,
    ];
    let filasAdicionales = []; // variable para guardar las filas adicionales cuando exista refrigerio y/o más de 1 jornada intermitente

    //por cada persona ir por cada día y agregar datos, si no estaba programado o no tenía horas se coloca vacío
    for (let index = 0; index < cantidadMaximaDeDiasAMostrar; index++) {
      if (persona[index + 1]) {
        persona[index + 1].forEach((diaAsistencia, indiceAsistencia) => {
          if (
            (diaAsistencia.hora_inicio_jornada ||
              diaAsistencia.hora_fin_jornada) &&
            (diaAsistencia.hora_inicio_jornada !== '' ||
              diaAsistencia.hora_fin_jornada !== '')
          ) {
            if (indiceAsistencia === 0) {
              rowDataPersona.push(
                ...[
                  'jornada',
                  diaAsistencia.hora_inicio_jornada
                    ? diaAsistencia.hora_inicio_jornada
                    : '',
                  diaAsistencia.hora_fin_jornada
                    ? diaAsistencia.hora_fin_jornada
                    : '',
                ]
              );
              if (
                persona.cantidadFilasAFusionar > 1 &&
                persona[index + 1].length === 1
              ) {
                if (!filasAdicionales[0] || filasAdicionales[0].length === 0) {
                  filasAdicionales[0] = ['', '', '', ''];
                }
                filasAdicionales[0].push(...['', '', '']);
              }
            } else {
              if (
                !filasAdicionales[indiceAsistencia - 1] ||
                filasAdicionales[indiceAsistencia - 1].length === 0
              ) {
                filasAdicionales[indiceAsistencia - 1] = ['', '', '', ''];
              }
              filasAdicionales[indiceAsistencia - 1].push(
                ...[
                  'jornada',
                  diaAsistencia.hora_inicio_jornada
                    ? diaAsistencia.hora_inicio_jornada
                    : '',
                  diaAsistencia.hora_fin_jornada
                    ? diaAsistencia.hora_fin_jornada
                    : '',
                ]
              );
            }

            if (
              (diaAsistencia.hora_inicio_almuerzo ||
                diaAsistencia.hora_fin_almuerzo) &&
              (diaAsistencia.hora_inicio_almuerzo !== '' ||
                diaAsistencia.hora_fin_almuerzo !== '')
            ) {
              if (
                !filasAdicionales[indiceAsistencia] ||
                !filasAdicionales[indiceAsistencia].length === 0
              ) {
                filasAdicionales[indiceAsistencia] = ['', '', '', ''];
              }
              filasAdicionales[indiceAsistencia].push(
                ...[
                  'refrigerio',
                  diaAsistencia.hora_inicio_almuerzo
                    ? diaAsistencia.hora_inicio_almuerzo
                    : '',
                  diaAsistencia.hora_fin_almuerzo
                    ? diaAsistencia.hora_fin_almuerzo
                    : '',
                ]
              );
            }
          } else {
            rowDataPersona.push(...['', '', '']);
            for (
              let index = 1;
              index < persona.cantidadFilasAFusionar;
              index++
            ) {
              if (
                !filasAdicionales[index - 1] ||
                filasAdicionales[index - 1].length === 0
              ) {
                filasAdicionales[index - 1] = ['', '', '', ''];
              }
              filasAdicionales[index - 1].push(...['', '', '']);
            }
          }
        });
      } else {
        rowDataPersona.push(...['', '', '']);
        for (let index = 1; index < persona.cantidadFilasAFusionar; index++) {
          if (
            !filasAdicionales[index - 1] ||
            filasAdicionales[index - 1].length === 0
          ) {
            filasAdicionales[index - 1] = ['', '', '', ''];
          }
          filasAdicionales[index - 1].push(...['', '', '']);
        }
      }
    }
    // Agregar los datos de la persona al sheet
    if (filasAdicionales.length > 0) {
      XLSX.utils.sheet_add_aoa(sheet, [rowDataPersona], { origin: -1 });
      filasAdicionales.forEach((fila) => {
        XLSX.utils.sheet_add_aoa(sheet, [fila], { origin: -1 });
      });
    } else {
      XLSX.utils.sheet_add_aoa(sheet, [rowDataPersona], { origin: -1 });
    }
  });

  // Crear el libro de Excel y escribir el archivo
  var wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, sheet, 'Datos');
  XLSX.writeFile(wb, name);
}

export { excelExport, excelExportHorasLaboradasPorMes };
